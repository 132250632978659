@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
  height: 100vh;
}

::selection { 
  color: #fff;
  background: #710060; 
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    
    header {
      height: 70;
      padding: 15px;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: calc(100% - 100px) 50px 50px;
      justify-content: start;
      align-items: center;
      background-color: #f2f2f2;
      margin-top: -10px;
      margin-right: -10px;
      margin-left: -10px;
      margin-bottom: 25px;

      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);

      .logo_wrapper{
        width: 160px;
        height: 40px;
        display: grid;
        justify-content: start;
        align-items: center;

        img{
          width: 100%;
          height: auto;
        }
      }
      
      .user_image_wrapper{
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img{
          width: 28px;
          height: 28px;
        }
      }
    }

    footer {
      position: fixed;
      bottom: 0;
      // left: 0;
      right: 0;
      z-index: 99;
      // width: 100%;
      height: 2rem;
      padding: 1.5rem;
      display: grid;
      place-content: center;
      justify-content: center;
      background-color: #151515;
      color:#fff;
      font-size: 10px;
      margin-top:10px;
    }

    @media (max-width: 768px) {
      overflow-x: hidden;
      header {
        grid-template-columns: calc(100% - 150px) 50px 50px 50px;

        .btn-toggle{
          justify-self: end;
        }
      }
    }
  }
  
  
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }
  }
}

.badge {
  padding: 5px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 5px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}


.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header > div {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 100%;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header .doctor_name{
  color: #afd1b3;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header .sub_heading{
  font-size: 8px;
  font-weight: 200;
  color:#ffffff;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header .welcome{
  font-size: 10px;
  font-weight: 100;
  color: #ffffff;
  text-transform: capitalize;
  letter-spacing: 0;
}

.doc-icon{
  position: absolute;
  top: 12px;
  left: 20px;
  width: 36px;
  height: auto;
  filter: invert(1);
}

.pro-sidebar-layout
{
	overflow-y: scroll;
}
.pro-sidebar-layout::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.pro-sidebar-layout::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.pro-sidebar-layout::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}

.slide-in-fwd-center {
	-webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

@media screen and (max-width:768px){
  .switch_btn_wrapper{
    visibility: hidden;
  }
  .app main footer,
  .pro-sidebar.collapsed ~ main > footer{
    width: 100%;
    padding: 15px;
    left: 0;
  }
}



